var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"change-clinic-modal","centered":"","hide-header":"","hide-footer":""},on:{"hidden":_vm.onHidden}},[_c('div',[_c('div',{staticClass:"change-modal-title"},[_vm._v("Alternar entre clínicas")]),_c('div',{staticClass:"change-modal-subtitle"},[_vm._v(" Você está mundando entre as clínicas: ")]),(_vm.oldClinic && _vm.newClinic)?_c('div',{staticClass:"clinics-wrapper"},[_c('Icon',{attrs:{"tooltip":_vm.oldClinic.name}},[_c('div',{staticClass:"clinic-box"},[_vm._v(" "+_vm._s(_vm.oldClinic.name)+" ")])]),_c('ArrowLeft',{staticClass:"arrow"}),_c('Icon',{attrs:{"tooltip":_vm.newClinic.name}},[_c('div',{staticClass:"clinic-box"},[_vm._v(" "+_vm._s(_vm.newClinic.name)+" ")])])],1):_vm._e(),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v(" Informe sua senha de login para continuar ")]),_c('validation-provider',{attrs:{"name":"senha","rules":{ required: true, min: 8 }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, dirty }){return [_c('div',{staticClass:"input-password"},[((!_vm.showPassword ? 'password' : 'text')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                error: _vm.password.length > 0 && errors.length > 0 && dirty
              },attrs:{"id":"password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.password)?_vm._i(_vm.password,null)>-1:(_vm.password)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.changeUser.apply(null, arguments)},"change":function($event){var $$a=_vm.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.password=$$c}}}}):((!_vm.showPassword ? 'password' : 'text')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                error: _vm.password.length > 0 && errors.length > 0 && dirty
              },attrs:{"id":"password","type":"radio"},domProps:{"checked":_vm._q(_vm.password,null)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.changeUser.apply(null, arguments)},"change":function($event){_vm.password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                error: _vm.password.length > 0 && errors.length > 0 && dirty
              },attrs:{"id":"password","type":!_vm.showPassword ? 'password' : 'text'},domProps:{"value":(_vm.password)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.changeUser.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),(_vm.showPassword == false)?_c('EyeSlash',{staticClass:"eye",on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}}):_vm._e(),(_vm.showPassword == true)?_c('Eye',{staticClass:"eye",on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}}):_vm._e()],1),(_vm.invalidPassword)?_c('div',{staticClass:"custom-invalid-feedback"},[_vm._v(" Senha incorreta ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"alert"},[_vm._v(" Finalize ou pause todas as tarefas em aberto para que nenhuma informação seja perdida! ")]),_c('div',{staticClass:"actions"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('b-button',{attrs:{"variant":"primary","disabled":!_vm.password},on:{"click":_vm.changeUser}},[_vm._v(" Confirmar ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }