<template>
  <b-modal
      id="change-clinic-modal"
      centered
      hide-header
      hide-footer
      @hidden="onHidden"
    >
      <div>

        <div class="change-modal-title">Alternar entre clínicas</div>
        <div class="change-modal-subtitle">
          Você está mundando entre as clínicas:
        </div>

        <div v-if="oldClinic && newClinic" class="clinics-wrapper">
          <Icon :tooltip="oldClinic.name">
            <div class="clinic-box">
              {{ oldClinic.name }}
            </div>
          </Icon>
          <ArrowLeft class="arrow" />
          <Icon :tooltip="newClinic.name">
            <div class="clinic-box">
              {{ newClinic.name }}
            </div>
          </Icon>
        </div>

        <div class="form-group">
          <label for="password">
            Informe sua senha de login para continuar
          </label>
          <validation-provider
            name="senha"
            :rules="{ required: true, min: 8 }"
            v-slot="{ errors, dirty }"
          >
            <div class="input-password">
              <input
                id="password"
                :type="!showPassword ? 'password' : 'text'"
                v-model="password"
                @keyup.enter="changeUser"
                class="form-control"
                :class="{
                  error: password.length > 0 && errors.length > 0 && dirty
                }"
              />
              <EyeSlash
                class="eye"
                v-if="showPassword == false"
                @click="showPassword = !showPassword"
              />
              <Eye
                class="eye"
                v-if="showPassword == true"
                @click="showPassword = !showPassword"
              />
            </div>
            <div v-if="invalidPassword" class="custom-invalid-feedback">
              Senha incorreta
            </div>
          </validation-provider>
        </div>

        <div class="alert">
          Finalize ou pause todas as tarefas em aberto para que nenhuma informação seja perdida!
        </div>

        <div class="actions">
          <b-button
            variant="outline-secondary"
            @click="closeModal"
          >
            Cancelar
          </b-button>
          <b-button
            variant="primary"
            :disabled="!password"
            @click="changeUser"
          >
            Confirmar
          </b-button>
        </div>

      </div>
    </b-modal>
</template>
<script>
import {
  setAccessToken,
  setRefreshToken,
  setCurrentUser,
  setCurrentClinic,
  setPermissions,
  userHasPermission
} from '@/utils/localStorageManager'

export default {
  components: {
    ArrowLeft: () => import('@/assets/icons/arrow-left.svg'),
    Eye: () => import('@/assets/icons/eye.svg'),
    EyeSlash: () => import('@/assets/icons/eye-slash.svg'),
    Icon: () => import('@/components/General/Icon')
  },
  props: {
    oldClinic: Object,
    newClinic: Object,
  },
  data() {
    return {
      showPassword: false,
      password: '',
      invalidPassword: false
    }
  },
  methods: {
    onHidden() {
      this.showPassword = false
      this.password = ''
      this.$emit('hidden')
    },
    closeModal() {
      this.$bvModal.hide('change-clinic-modal')
    },
    async changeUser() {
      const isLoading = this.$loading.show()
      this.invalidPassword = false;
      try {
        const userNameRes = await this.api.checkUserPassword(this.password);
        if(!userNameRes?.data){
          this.invalidPassword = true;
          return
        }
        await this.api.logout()
        const res = await this.api.login({ email: userNameRes.data, password: this.password, clinicId: this.newClinic.id })
        const resData = res.data
        const clinic = resData.user.clinics.find(el => el.id === this.newClinic.id)
        setAccessToken(resData.accessToken)
        setRefreshToken(resData.refreshToken)
        setCurrentUser(resData.user)
        setCurrentClinic(clinic)
        setPermissions(resData.user.permissions)
        setTimeout(() => {
          clinic.role.includes('DOCTOR') || !userHasPermission('FpAgen1') ? this.$router.push('/visao-geral') : this.$router.push('/agenda')
          location.reload()
        }, 400);
      } catch(err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
      }
    }
  }
}
</script>
<style lang="scss">
#change-clinic-modal {
  .change-modal-title {
    font-weight: 600;
    font-size: 18px;
    color: #525C7A;
  }
  .change-modal-subtitle {
    font-size: 14px;
    color: #525C7A;
  }
  .clinics-wrapper {
    width: 100%;
    margin: 40px 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
  .clinic-box {
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid var(--neutral-400);
    color: var(--neutral-400);
    text-align: center;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .arrow {
    width: 24px;
    height: 24px;
    stroke: var(--neutral-400);
    transform: rotate(180deg);
  }
  .alert {
    border-radius: 4px;
    border: 1px dashed var(--orange);
    color: var(--orange);
    text-align: center;
  }
  .actions {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
