<template>
  <div>
    <multiselect
      class="multiselect-topbar"
      v-model="clinic"
      :options="clinics"
      track-by="id"
      label="name"
      placeholder="Selecionar clínica..."
      :showLabels="false"
      :searchable="false"
      :allowEmpty="false"
      :close-on-select="true"
      @select="clinicSelect"
    >
      <template slot="caret">
        <div class="chevron">
          <ChevronDown />
        </div>
      </template>

      <template slot="noOptions">
        Nenhuma opção
      </template>

      <template slot="noResult">
        Nenhum resultado
      </template>
    </multiselect>

    <ChangeClinicModal
      :oldClinic="oldClinic"
      :newClinic="clinic"
      @hidden="onHiddenModal"
    />
  </div>
</template>
<script>
import { getCurrentUser, getCurrentClinic } from '@/utils/localStorageManager'
import { loadDefaultClinicStates } from '@/utils/storeHelper'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import ChangeClinicModal from './ChangeClinicModal'
export default {
  components: { ChevronDown, ChangeClinicModal },
  data() {
    const user = getCurrentUser()
    return {
      user,
      oldClinic: null,
      clinic: getCurrentClinic(),
      clinics: [],
    }
  },
  mounted() {
    this.clinics = this.user.clinics.filter(el => el.id !== this.clinic.id)
    loadDefaultClinicStates()
  },
  methods: {
    clinicSelect() {
      this.$bvModal.show('change-clinic-modal')
    },
    onHiddenModal() {
      this.clinic = getCurrentClinic()
    }
  },
  watch: {
    clinic(newVal, oldVal) {
      this.oldClinic = oldVal
      newVal && loadDefaultClinicStates()
    }
  }
}
</script>
